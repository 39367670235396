import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { getSeasonMapData } from "../common"
import SEO from "../components/seo"

export default ({ data, classes, pageContext }) => {
  const title = "Season " + pageContext.seasonLabel
  const mapData = JSON.stringify(
    getSeasonMapData(
      data.allMysqlSeasonAverageByComp.edges,
      data.allMysqlCompetitions.edges
    )
  )

  return (
    <Layout>
      <SEO title={title} />
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
                if(document.getElementById('map-container') == null){
                  window.onload = function() {
                    insertSeasonMap('map-container', 'Season 2018/19', ${mapData});
                };
                } else {
                  insertSeasonMap('map-container', 'Season 2018/19', ${mapData});
                }
                `,
          },
        ]}
      />
      <h1>{title}</h1>
      <div className="row justify-content-center">
        <div className="col-12">
          <div id="map-container"></div>
        </div>
      </div>
      <h2>Average attendance by competition:</h2>
      <div class="subsection row justify-content-center">
        <div class="col-12 col-md-9">
          <table class="table table-dark">
            <thead>
              <tr>
                <th scope="col" className="text-right">
                  #
                </th>
                <th scope="col">Competition</th>
                <th scope="col">Average attendance</th>
              </tr>
            </thead>
            <tbody>
              {data.allMysqlSeasonAverageByComp.edges.map((edge, key) => (
                <tr key={key}>
                  <th scope="row" className="text-right">
                    {key + 1}
                  </th>
                  <td>
                    <a
                      href={`/compseason/${edge.node.compid}/${edge.node.season}`}
                    >
                      {edge.node.label || edge.node.short}
                    </a>
                  </td>
                  <td>{edge.node.average}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($seasonId: String!) {
    allMysqlSeasonAverageByComp(filter: { season: { eq: $seasonId } }) {
      edges {
        node {
          id
          label
          average
          compid
          season
          short
        }
      }
    }
    allMysqlCompetitions {
      edges {
        node {
          mysqlId
          label
          logo
          type
          short
          latitude
          longitude
          teams
        }
      }
    }
  }
`
